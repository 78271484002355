<template>
  <el-dialog
    title="请选择"
    width="800px"
    @closed="closed"
    :visible.sync="dialogVisible"
    :modal="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :wrapperClosable="false"
    :append-to-body="true"
    :modal-append-to-body="true"
  >
    <div class="padding-lr">

      <el-form :inline="true" class="demo-form-inline">

        <el-form-item>
          <el-input class=""
                    placeholder="搜索关键字"
                    prefix-icon="el-icon-search"
                    v-model="searchData.keyword"
                    @keyup.enter.native="search"
                    style="width: 260px">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-select v-model="searchData.status"
                     placeholder="上架状态"
                     style="width: 120px">
            <el-option
              v-for="item in saleStatusArray"
              :disabled="item.disabled"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" round @click="search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        height="400"
        ref="multipleTable"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%;overflow-y: auto;"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="id" label="商品编码" width="180"></el-table-column>
        <el-table-column label="封面图" width="100">
          <template slot-scope="scope">
            <img class="goods-cover" :src="scope.row.cover" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="160"></el-table-column>
        <el-table-column
          label="状态"
          width="100">
          <template slot-scope="scope">
            {{saleStatus[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="售价"
          width="80">
        </el-table-column>
      </el-table>
      <div class="paging-row">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalListNum"
        ></el-pagination>
      </div>
    </div>
    <div class="text-center margin-tb">
      <el-button type="info" @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  goodsReviewStatusArray,
  saleStatus,
  saleStatusArray,
  isDeleteStatus,
  isDeleteStatusArray
} from "../../../assets/js/status";

import { URL } from "../../../config/url.supplier.js";

import { disposeSearchData, parseTimeArray } from "../../../assets/js/utils";

export default {
  name: "selectGoods",
  props: {
    value: Object,
    visible: Boolean,
    firstSupplierCategoryId: {
      type: Object,
      default: ""
    }
  },
  data() {
    return {
      multipleSelection: [],
      dialogVisible: false,
      loading: false,
      /* 数据表格 */
      saleStatus,
      saleStatusArray,
      isDeleteStatus,
      isDeleteStatusArray,
      goodsReviewStatusArray: goodsReviewStatusArray.filter(res =>
        [1].includes(res.id)
      ),
      searchKeyType: [
        {
          id: "goodsId",
          name: "商品编码"
        },
        {
          id: "goodsName",
          name: "商品名称"
        },
        {
          id: "brandName",
          name: "品牌名称"
        },
        {
          id: "supplierName",
          name: "商家名称"
        }
      ],
      classifyList: [],
      searchData: {
        status: 0,
        keyword: ""
      },
      tableData: [], // 数据列表
      currentPage: 1, // 当前页码
      totalListNum: 0, // 列表总条量
      pageSize: 10 // 每页显示得条数
    };
  },
  created() {
    if (this.visible) {
      this.dialogVisible = this.visible;
    }
  },
  methods: {
    cancel() {
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.dialogVisible = false;
    },
    closed() {
      this.$emit("closed", ...arguments);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    confirm() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择一条数据");
      }

      let list = [];
      this.multipleSelection.forEach(item => {
        list.push({
          firstSupplierCategoryId: this.firstSupplierCategoryId
            .firstSupplierCategoryId,
          goodsId: item.id
        });
      });

      this.loading = true;
      this.axios
        .post(URL.supplier.saveBatch,list)
        .then(res => {
         this.$message({
          message: '成功！',
          type: 'success'
        });
          this.cancel();
        })
        .catch(res => {
          this.loading = false;
        })
        .finally(res => {
          this.loading = false;
        });
    },
    /**
     * 拼接搜索参数
     */
    jointSearchData() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        firstSupplierCategoryId: this.firstSupplierCategoryId
          .firstSupplierCategoryId
      };
      data = Object.assign(data, this.searchData);
      disposeSearchData(data);
      return data;
    },
    /**
     * 搜索
     */
    search() {
      this.currentPage = 1;
      this.getList();
    },
    /**
     * 查询列表数据
     */
    getList() {
      return new Promise((resolve, reject) => {
        let data = this.jointSearchData();
        this.loading = true;
        this.axios
          .get(URL.supplier.chooseGoods, { params: data })
          .then(res => {
            parseTimeArray(res.data.records, ["updateTime", "onShelfTime"]);
            this.totalListNum = res.data.total;
            this.tableData = res.data.records;
            resolve(res);
          })
          .catch(res => {
            this.tableData = [];
            // reject(res);
          })
          .finally(res => {
            this.loading = false;
          });
      });
    },
    /**
     * pageSize 改变时会触发：选择每页显示条数
     */
    handleSizeChange(val, type) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    /**
     * currentPage 改变时会触发：选择页数
     */
    handleCurrentChange(val, type) {
      this.currentPage = val;
      this.getList();
    }
  },
  watch: {
    visible(newValue) {
      this.dialogVisible = newValue;
      if (newValue) {
        this.getList();
      }
    },
    dialogVisible(newValue) {
      console.log("watch dialogVisible", newValue);
      this.$emit("update:visible", newValue);
    },
  }
};
</script>

<style lang="less" scoped>
.goods-cover {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
</style>
