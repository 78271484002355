<template>
  <div class="pages" v-loading="loading">
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-button class type="primary" icon="el-icon-plus" @click="showAddNewClass()">新增分类</el-button>
      </el-form-item>
    </el-form>

    <div class="classify-tree-wrap">
      <el-tree
        :data="treeData"
        :show-checkbox="false"
        :expand-on-click-node="false"
        :default-expanded-keys="treeDataDefaultsOpen"
        :default-expand-all="false"
        :filter-node-method="filterTree"
        ref="tree"
        @node-expand="treeOpen"
        @node-collapse="treeClose"
        node-key="id"
      >
        <span class="custom-tree-node" slot-scope="{data,node}">
          <span class="classify flex-item">
            <span class="left" :class="data.status===2?'disabled':''">{{data.name}}</span>
            <span class="content"></span>
            <template v-if="data.status ===1">
              <el-tooltip class="margin-left-10" effect="dark" content="查看商品" placement="right-end">
                <el-button
                  class
                  type="primary"
                  size="mini"
                  icon="el-icon-connection"
                  circle
                  @click.stop="showAlreadyRelate(data)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="margin-left-10"
                effect="dark"
                content="关联商品"
                placement="right-end"
                v-if="node.level<2"
              >
                <el-button
                  class
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  circle
                  plain
                  @click.stop="addNewGoodsClass(data.id)"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="margin-left-10" effect="dark" content="编辑" placement="right-end">
                <el-button
                  class
                  type
                  size="mini"
                  icon="el-icon-edit"
                  circle
                  @click.stop="showAddNewClass(data)"
                ></el-button>
              </el-tooltip>
            </template>
            <el-tooltip
              class="margin-left-10"
              effect="dark"
              :content="data.status === 1?'禁用':'启用'"
              placement="right-end"
            >
              <el-button
                class
                type="danger"
                size="mini"
                icon="el-icon-switch-button"
                circle
                @click.stop="triggerSwitchStatus(data,node)"
                v-if="data.status === 1"
              ></el-button>
              <el-button
                class
                type="primary"
                size="mini"
                icon="el-icon-switch-button"
                circle
                @click.stop="triggerSwitchStatus(data,node)"
                v-if="data.status === 2"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              v-if="data.status === 2"
              class="margin-left-10"
              effect="dark"
              content="删除"
              placement="right-end"
            >
              <el-button
                class
                type="danger"
                size="mini"
                icon="el-icon-delete"
                circle
                @click.stop="deleteGoodsClass(data,node)"
              ></el-button>
            </el-tooltip>
          </span>
        </span>
      </el-tree>
    </div>

    <el-dialog
      :visible.sync="visibleAddNew"
      :title="formAddNew.id?('修改'+formAddNew.name||'分类'):'新增分类'"
      center
      width="400px"
    >
      <el-form
        class="form-new-groups"
        :model="formAddNew"
        ref="formNameAddNew"
        :rules="formRulesAddNew"
        label-width="80px"
      >
        <el-form-item label="parentID" v-if="formAddNew.parentId" v-show="false">
          <el-input placeholder="ID" v-model="formAddNew.parentId" disabled></el-input>
        </el-form-item>
        <el-form-item label="ID" v-if="formAddNew.id" v-show="false">
          <el-input placeholder="ID" v-model="formAddNew.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input placeholder="分类名称" v-model="formAddNew.name"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input placeholder="排序" v-model="formAddNew.sort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="formAddNew.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('formNameAddNew',formAddNew)" size>提交</el-button>
          <el-button @click="resetForm('formNameAddNew')" size>重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 展示单个分类关联的商品 -->
    <el-drawer :title="classification" :visible.sync="table" direction="rtl" size="50%">
      <el-alert type="success" title="可以点击商品拖动进行排序!~" :closable="false"></el-alert>
      <div class="drawer_rtl wrap_table">
        <el-table :data="gridData" fixed class="" row-key="id">
          <el-table-column property="goodsId" label="商品编码" width="200"></el-table-column>
          <el-table-column property="name" label="封面图" width="150">
            <template slot-scope="scope">
              <img class="goods-cover" :src="scope.row.goodsCover" />
            </template>
          </el-table-column>
          <el-table-column property="goodsName" label="商品名称"></el-table-column>
          <el-table-column label="移除商品">
            <template slot-scope="scope">
              <el-button @click="deleteGoods(scope.row,scope.$index)" type="danger" size="small">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="confirm_sort"><el-button type="primary" size="small" @click="confirmSort">确认排序</el-button></div>
    </el-drawer>

    <select-store-category-goods
      :firstSupplierCategoryId="{firstSupplierCategoryId}"
      :visible.sync="showGoodsDialog"
    ></select-store-category-goods>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import { URL } from "../../../config";
import { sort,categoryName} from "../../../assets/js/formRules";
import { disposeTreeData } from "../../../assets/js/goodsClassify";
import SelectStoreCategoryGoods from "../../../components/supplier/select/selectStoreCategoryGoods";
export default {
  name: "storeCategory",
  data() {
    return {
      classification: '',
      table: false,
      dialog: false,
      loading: false,
      gridData: [],
      firstSupplierCategoryId: "",
      sourceObj: {
        id: "",
        img: "",
        name: ""
      },
      showGoodsDialog: false,
      loading: false,
      /* 分类树状图 */
      treeData: [],
      treeDataDefaultsOpen: [],
      treeFilterShowIds: [],
      treeDataOrigin: [],
      treeDataLevel: [],
      /* 新增分类 */
      visibleAddNew: false,
      formRulesAddNew: {
        name: categoryName,
        sort,
        status: [
          { trigger: "blur", required: true, message: "请选择状态" },
          { trigger: "blur", type: "number", message: "状态值异常" }
        ]
      },
      formAddNew: {
        id: "",
        parentId: 0,
        name: "",
        sort: 0,
        status: 1
      },
      tablePagingCanRelate: {
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10 // 每页显示得条数
      }
    };
  },
  components: {
    SelectStoreCategoryGoods
  },
  created() {
    document.body.ondrop = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.treeDataDefaultsOpen = this.storageTreeDefaultsExpandedKeys("get");
    this.getList();
  },
  watch: {
    sourceObj: function(newValue) {
      let item = this.editItem;
      item.source = newValue.id;
      item.sourceJson = newValue;
      this.form.source = newValue.id;
    }
  },
  methods: {
    // 删除分类
    deleteGoodsClass(data,node) {
      var _this = this;
      this.$confirm('确认删除分类?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.loading = true;
          _this.axios
            .post(URL.supplier.deleteCate + '?id=' + data.id)
            .then(res => {
              _this.$message({
                message: '删除成功',
                type: 'success'
              });
              _this.getList();
            })
            .catch(res => {
              _this.loading = false;
            })
            .finally(res => {
              _this.loading = false;
            });
        }).catch(() => {

        });
    },
    // 行拖拽
    rowDrop () {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.gridData.splice(oldIndex, 1)[0]
          _this.gridData.splice(newIndex, 0, currRow)
        }
      })
    },
    // 确认分类
    confirmSort() {
      this.gridData.forEach((item,index) => {
        item.sort = index;
      })

      this.loading = true;
      this.axios
        .post(URL.supplier.saveBatch,this.gridData)
        .then(res => {
         this.$message({
          message: '成功！',
          type: 'success'
        });

        })
        .catch(res => {
          this.loading = false;
        })
        .finally(res => {
          this.loading = false;
        });
    },
    /**
     * 获取分类列表
     */
    getList() {
      this.loading = true;
      this.axios
        .get(URL.supplier.cateList, {})
        .then(res => {
          this.treeFilterShowIds = [];
          this.treeData = disposeTreeData(res.data);
          this.treeDataOrigin = res.data;
          return Promise.resolve(res);
        })
        .catch(res => {
          this.treeDataOrigin = [];
          this.treeData = [];
        })
        .finally(res => {
          this.loading = false;
        });
    },
    // 新增
    addNewGoodsClass(data) {
      this.firstSupplierCategoryId = data;
      this.showGoodsDialog = true;
    },
    /**
     * tree 树状图展开收起
     * */
    treeOpen(data, node, element) {
      if (!this.treeDataDefaultsOpen.includes(data.id)) {
        this.treeDataDefaultsOpen.push(data.id);
        this.storageTreeDefaultsExpandedKeys();
      }
    },
    treeClose(data, node, element) {
      let each = data => {
        if (this.treeDataDefaultsOpen.includes(data.id)) {
          this.treeDataDefaultsOpen.splice(
            this.treeDataDefaultsOpen.indexOf(data.id),
            1
          );
        }
        if (data.children instanceof Array && data.children.length) {
          data.children.forEach(res => {
            each(res);
          });
        }
      };
      each(data);
      this.storageTreeDefaultsExpandedKeys();
    },
    treeOpenParentId(data) {
      if (data.parentId && data.parentId !== "0")
        this.treeOpen({ id: data.parentId });
    },
    treeCloseParentId(data) {
      if (data.parentId && data.parentId !== "0")
        this.treeClose({ id: data.parentId });
    },
    storageTreeDefaultsExpandedKeys(arr = this.treeDataDefaultsOpen) {
      let name = "classify_tree_default_open_keys";
      if (arr === "get") {
        return JSON.parse(localStorage.getItem(name) || "[]") || [];
      }
      localStorage.setItem(name, JSON.stringify(arr));
    },
    /**
     * tree 过滤
     * */
    filterTree(value, data, node) {
      let judge = true;
      let showArr = this.treeFilterShowIds; // 存储 filter = true 的id，改id的子集也将被展示
      if (!judge) {
        if (showArr.includes(data.parentId)) judge = true;
      }
      if (judge) {
        if (!showArr.includes(data.id)) showArr.push(data.id);
      }
      return judge;
    },
    /**
     * 显示、隐藏 可被的关联属性项列表弹窗
     * */
    showAddNewClass(row = { parentId: "0" }) {
      this.visibleAddNew = true;
      this.formAddNew = Object.assign(
        {
          name: "",
          sort: 0,
          status: 1
        },
        row
      );
    },
    hideAddNewClass() {
      this.visibleAddNew = false;
    },
    /**
     * 新增分类
     */
    addAddNew() {
      let data = this.formAddNew;
      this.loading = true;
      this.axios
        .post(URL.supplier.saveCate, data)
        .then(res => {
          this.hideAddNewClass();
          this.getList();
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    /**
     * 切换分类启用、禁用
     */
    triggerSwitchStatus(data, node) {
      let obj = {
        categoryId: data.id
      };
      if (data.status == 1) obj.status = 2;
      else obj.status = 1;
      this.loading = true;
      this.axios
        .post(
          URL.supplier.enableOrDisableCate +
            "?categoryId=" +
            data.id +
            "&status=" +
            obj.status
        )
        .then(res => {
          this.$set(data, "status", obj.status);
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    /**
     * 显示、隐藏 已关联列表弹窗
     */
    showAlreadyRelate(row) {
      this.classification = row.name;
      // 获取店铺下关联的商品
      this.getSupplierCategory(row);
      this.table = true;
    },
    getSupplierCategory(row) {
     this.loading = true;
     let data = {
       firstSupplierCategoryId: row.id
     }
      this.axios
        .get(
          URL.supplier.goodsList, { params: data })
        .then(res => {
          this.gridData = res.data;
          this.rowDrop();
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    // 分类下的商品移除
    deleteGoods(row,index) {
      this.loading = true;
      this.axios
        .post(
          URL.supplier.goodsDelete + '?ids=' + row.id)
        .then(res => {
          this.$message({
            message: '成功!',
            type: 'success'
          });
          this.gridData.splice(index,1);
        })
        .catch(res => {})
        .finally(res => {
          this.loading = false;
        });
    },
    /**
     * 表单提交及重置
     * @param formName
     */
    submitForm(formName, data) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.addAddNew(data);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="less">
@import (reference) "../../../assets/css/data";
.classify-tree-wrap {
  .el-tree {
    & > .el-tree-node {
      & + .el-tree-node {
        margin-top: 10px;
      }
      &.is-expanded {
        box-shadow: 0 0 1px #999;
      }
    }
    .el-tree-node__content {
      padding-right: @padding-primary;
    }
  }
}
</style>
<style lang="less" scoped>
@import (reference) "../../../assets/css/data";

.classify-tree-wrap {
  width: 400px;
  .custom-tree-node,
  .classify {
    width: 100%;
    .disabled {
      color: #ccc;
    }
  }
}

.goods-cover {
  width: 60px;
  height: 60px;
}

.drawer_rtl {
  padding: 20px;
}

.confirm_sort {
  margin-top: 10px;
  padding-right: 20px;
  text-align: right;
}

.wrap_table {
  height: 65vh;
  overflow: auto;
}
</style>
